import {
  performPostRequest,
  performAuthenticatedPostRequest,
  performAuthenticatedGetRequest,
  performAuthenticatedPutRequest,
  performAuthenticatedDeleteRequest
} from './handlers';

export function loginRequest(email, password, mfaCode = '') {
  const additional = {};
  if (mfaCode !== '') {
    additional.mfaCode = mfaCode;
  }
  return performPostRequest(
    'api/v1/auth/login',
    {
      email,
      password
    },
    false
  );
}

export function getUserFromTokenRequest() {
  return performAuthenticatedGetRequest('api/v1/auth/user-from-token');
}

export function resetPasswordRequest(password, confirmPassword, userId) {
  return performAuthenticatedPostRequest(
    `api/v1/auth/reset-password/${userId}`,
    {
      password,
      confirm_password: confirmPassword
    },
    false
  );
}

// CUSTOMERS API RESOURCES //

export function listCustomersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-customers', params);
}

export function searchCustomersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-customers', params);
}

export function addCustomerRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-customer', payload);
}

export function getCustomerByIDRequest(customerID) {
  return performAuthenticatedGetRequest(`api/v1/customer/${customerID}`);
}

export function editCustomerRequest(customerID, payload) {
  return performAuthenticatedPutRequest(`api/v1/customer/${customerID}`, payload);
}

export function deleteCustomerCellphoneRequest(cellphoneID) {
  return performAuthenticatedDeleteRequest(`api/v1/cellphone/${cellphoneID}`);
}

export function deleteCustomerRequest(customerID) {
  return performAuthenticatedDeleteRequest(`api/v1/customer/${customerID}`);
}

// Suppliers API Resources //
export function listSuppliersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-suppliers', params);
}

export function searchSuppliersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-suppliers', params);
}

export function addSupplierRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-supplier', payload);
}

// Warehouses API Resources
export function listWarehousesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-warehouses', params);
}

export function searchWarehousesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-warehouses', params);
}

export function addWarehouseRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-warehouse', payload);
}
// Goods API Resources
export function listGoodsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-goods', params);
}

export function searchGoodsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-goods', params);
}

export function addGoodRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-good', payload);
}

export function getGoodByIDRequest(goodID) {
  return performAuthenticatedGetRequest(`api/v1/edit-good/${goodID}`);
}

export function getGoodConsignmentsByID(goodID) {
  return performAuthenticatedGetRequest(`api/v1/list-good-consignments`, { good: goodID });
}

export function deleteGoodRequest(goodID) {
  return performAuthenticatedDeleteRequest(`api/v1/good/${goodID}`);
}

export function editGoodRequest(goodID, payload) {
  return performAuthenticatedPutRequest(`api/v1/edit-good/${goodID}`, payload);
}

// Price Categories API Resources
export function listPriceCategoriesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-price-categories', params);
}

export function searchPriceCategoriesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-price-categories', params);
}

export function addPriceCategoryRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-price-category', payload);
}

export function getCategoryByID(categoryID) {
  return performAuthenticatedGetRequest(`api/v1/price-category/${categoryID}`);
}

export function ediPriceCategoryRequest(categoryID, payload) {
  return performAuthenticatedPutRequest(`api/v1/price-category/${categoryID}`, payload);
}

// Consignments API Resources
export function listConsignmentsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-consignments', params);
}

export function getConsignmentByIDRequest(consignmentID) {
  return performAuthenticatedGetRequest(`api/v1/consignment/${consignmentID}`);
}

export function searchConsignmentsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-consignments', params);
}

export function listCustomerCargosRequest(params) {
  return performAuthenticatedGetRequest(`api/v1/list-customer-cargos`, params);
}

export function createConsignmentRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/create-consignment', payload);
}

export function getGoodConsignmentsByConsignmentID(consignmentID) {
  return performAuthenticatedGetRequest(`api/v1/list-good-consignments`, { consignment: consignmentID });
}

export function deleteConsignmentRequest(consignmentID) {
  return performAuthenticatedDeleteRequest(`api/v1/consignment/${consignmentID}`);
}

export function editConsignmentRequest(consignmentID, payload) {
  return performAuthenticatedPutRequest(`api/v1/consignment/${consignmentID}`, payload);
}

// Containers API Resources
export function listContainersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-containers', params);
}

export function searchContainersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-containers', params);
}

export function addContainerRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-container', payload);
}

export function unloadConsignmentsRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/unload-consignments', payload);
}

export function editContainerRequest(containerID, payload) {
  return performAuthenticatedPutRequest(`api/v1/container/${containerID}`, payload);
}

export function deleteContainerRequest(containerID) {
  return performAuthenticatedDeleteRequest(`api/v1/container/${containerID}`);
}

export function editContainerStatusRequest(params) {
  return performAuthenticatedPutRequest(`api/v1/update-container/${params}`, {});
}

export function loadConsignmentsRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/load-consignments', payload);
}

export function containerPackingListRequest(containerID) {
  return performAuthenticatedGetRequest(`api/v1/container-packing-list/${containerID}`);
}

// Text SMS API Resources
export function listSMSRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-sms', params);
}

export function addSMSRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-sms', payload);
}

// CURRENCY RATE
export function addCurrencyRateRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/currency-rate', payload);
}

export function getCurrencyRateRequest() {
  return performAuthenticatedGetRequest('api/v1/currency-rate');
}

// Bills / Invoices API Resources
export function listInvoicesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-bills', params);
}

export function searchInvoicesRequest(searchValue = null) {
  const queryParameters = [searchValue !== null ? `search=${searchValue}` : ''].join('&');
  const method = `api/v1/search-invoices${queryParameters ? `?${queryParameters}` : ''}`;
  return performAuthenticatedGetRequest(method);
}

export function createInvoiceRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/create-bill', payload);
}

export function cancelInvoiceRequest(billId, payload) {
  return performAuthenticatedPostRequest(`api/v1/cancel-bill/${billId}`, payload);
}

export function addDiscountRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-bill-discount', payload);
}

export function listDiscountsRequest(page = null, searchValue = null, params = {}) {
  const { invoiceID = null } = params;
  return performAuthenticatedGetRequest('api/v1/list-discounts', { page, search: searchValue, i: invoiceID });
}

export function addPaymentRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/bill-payment', payload);
}

export function listPaymentsRequest(params = {}) {
  const { customerID = null, invoiceID = null, ...others } = params;
  const method = `api/v1/list-bill-payments`;
  return performAuthenticatedGetRequest(method, { c: customerID, i: invoiceID, ...others });
}

export function searchPaymentsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-bill-payments', params);
}

export function cancelPaymentRequest(paymentId, payload) {
  return performAuthenticatedPostRequest(`api/v1/cancel-payment/${paymentId}`, payload);
}

export function getInvoiceByIDRequest(invID) {
  return performAuthenticatedGetRequest(`api/v1/bill/${invID}`);
}

// Dispatches API Resources

export function listConsignmentDispatchesRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-dispatches', params);
}

export function authorizeDispatchRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/authorize-dispatch', payload);
}

export function dispatchConsignmentsRequest(dispatchID, payload) {
  return performAuthenticatedPutRequest(`api/v1/dispatch-consignments/${dispatchID}`, payload);
}

export function deleteDispatchRequest(dispatchID) {
  return performAuthenticatedDeleteRequest(`api/v1/delete-dispatch/${dispatchID}`);
}

// USERS && ROLES RESOURCES
export function listUsersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/list-users', params);
}

export function searchUsersRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-users', params);
}

export function createStaffRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-staff', payload);
}

export function listRolesRequest() {
  return performAuthenticatedGetRequest('api/v1/roles');
}
export function addRoleRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/roles', payload);
}

export function editRoleRequest(id, payload) {
  return performAuthenticatedPutRequest(`api/v1/role/${id}`, payload);
}

export function getRoleByIdRequest(id) {
  return performAuthenticatedGetRequest(`api/v1/role/${id}`);
}

// REPORTS APIs
export function getClosingRequest(userID) {
  return performAuthenticatedGetRequest(`api/v1/closing-report/${userID}`);
}

// BLS APIs
export function listBillOfLadingsRequest(params) {
  return performAuthenticatedGetRequest(`api/v1/list-bls`, params);
}

export function searchBillOfLadingsRequest(params) {
  return performAuthenticatedGetRequest('api/v1/search-bls', params);
}

export function addBillOfLadingRequest(payload) {
  return performAuthenticatedPostRequest('api/v1/add-bl', payload);
}
